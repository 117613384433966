import { collection, deleteDoc, doc, getDocs } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { db } from '../firebase/firebase';
import { ThreeDots } from 'react-loader-spinner'
import './styles/users.css'

const Users = () => {
  const navigate = useNavigate();

  const [shouldComponentUpdate, setShouldComponentUpdate] = useState(true)
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'descending' })

  const fetchUsers = async () => {
    const querySnapshot = await getDocs(collection(db, "users"));

    let tempArr = [];
    querySnapshot.forEach((doc) => {
      if (doc.data()) {
        tempArr.push({ ...doc.data(), id: doc.id });
      }
    });
    tempArr.sort((a, b) => b.createdAt - a.createdAt)
    setUsers(tempArr)
    setLoading(false)
  }

  const deleteUser = async (id) => {
    setLoading(true)
    await deleteDoc(doc(db, 'users', id))
    setLoading(false)
    setShouldComponentUpdate(prev => !prev)
  }

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortedUsers = () => {
    const sortableUsers = [...users];
    if (sortConfig.key) {
      sortableUsers.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableUsers;
  };

  useEffect(() => {
    setLoading(true)
    fetchUsers()
  }, [shouldComponentUpdate])

  if (loading) {
    return <div className='loader'>
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#2D3748"
        ariaLabel="three-dots-loading"
        visible={true}
      />
    </div>
  }

  return (
    <main className='page'>
      <div className='header'>
        <h1 className='headertext'>Users</h1>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button className='headerBtn' onClick={() => navigate('/adduser')}>Add User</button>
        </div>
      </div>

      <table>
        <thead style={{ borderBottomWidth: '1px' }}>
          <tr>
            <th onClick={() => requestSort('name')}>
              Name {sortConfig.key === 'name' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
            </th>
            <th onClick={() => requestSort('response')}>
              Completed {sortConfig.key === 'response' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
            </th>
            <th onClick={() => requestSort('createdAt')}>
              User Added {sortConfig.key === 'createdAt' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
            </th>
            <th>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {getSortedUsers().map(user => {
            return <tr style={{ borderBottomWidth: '1px' }} key={user.link}>
              <td
                className='tableData'>
                {user.name}
              </td>
              <td
                className='tableCompleted'>
                {user.response ? <p style={{ color: 'green' }}>Yes</p> : <p style={{ color: 'red' }}>No</p>}
              </td>
              <td
                className='tableData'>
                {new Date(user.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}
              </td>
              <td
                className='tableDataActions'>
                <button className='viewQuestionsBtn' onClick={() => navigate(`/questions/${user.id}`)}>View Details</button>

                {!user.preventDelete && (
                  <button className='deleteBtn' onClick={() => deleteUser(user.id)}>Delete</button>
                )}
              </td>
            </tr>
          })}
        </tbody>
      </table>
    </main>
  )
}

export default Users