import React, { useState, useRef } from 'react';
import './styles.css';

const Tooltip = ({ children, content, position = 'top' }) => {
    const [isVisible, setIsVisible] = useState(false);
    const containerRef = useRef(null);

    const showTooltip = () => setIsVisible(true);
    const hideTooltip = () => setIsVisible(false);

    return (
        <div
            className="tooltip-container"
            ref={containerRef}
            onMouseEnter={showTooltip}
            onMouseLeave={hideTooltip}
        >
            <div className="tooltip-trigger">
                {children}
            </div>
            {isVisible && (
                <div className={`tooltip-content tooltip-${position}`}>
                    {content}
                </div>
            )}
        </div>
    );
};

export default Tooltip; 