import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { QRCodeSVG } from 'qrcode.react';
import React, { useEffect, useState } from 'react'
import { ThreeDots } from 'react-loader-spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { List, arrayMove } from 'react-movable';
import { db } from '../firebase/firebase';
import './styles/questions.css'
import Tooltip from '../components/Tooltip';

const Questions = () => {
    const navigate = useNavigate()
    const { id } = useParams();

    const [user, setUser] = useState({})
    const [loading, setLoading] = useState(false)
    const [copied, setCopied] = useState(false)
    const [questions, setQuestions] = useState([])
    const [allowMultipleResponses, setAllowMultipleResponses] = useState(false)
    const [preventDelete, setPreventDelete] = useState(false)
    const [rawVideosExpanded, setRawVideosExpanded] = useState(false)
    const [responseVideosExpanded, setResponseVideosExpanded] = useState(false)
    const linkOfTheDocument = `${user.link}?data=${user.id}`;

    const toggleRawVideos = () => {
        setRawVideosExpanded(!rawVideosExpanded);
    }

    const toggleResponseVideos = () => {
        setResponseVideosExpanded(!responseVideosExpanded);
    }

    const fetchUser = async () => {
        setLoading(true)
        try {
            const data = await getDoc(doc(db, 'users', id));
            setUser({ ...data.data(), id: data.id })
            setQuestions(data.data().questions)
            setAllowMultipleResponses(data.data().allowMultipleResponses || false)
            setPreventDelete(data.data().preventDelete || false)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!id) {
            navigate('/')
        }
        fetchUser()
    }, [])

    const copyText = () => {
        setCopied(true)
        navigator.clipboard.writeText(linkOfTheDocument)
        setTimeout(() => {
            setCopied(false)
        }, 1500)
    }

    const drop = async ({ oldIndex, newIndex }) => {
        setQuestions(arrayMove(questions, oldIndex, newIndex))

        await updateDoc(doc(db, 'users', user.id), {
            questions: arrayMove(questions, oldIndex, newIndex)
        })
    };

    const handleAllowMultipleResponsesChange = async (e) => {
        const newValue = e.target.checked
        setAllowMultipleResponses(newValue)
        await updateDoc(doc(db, 'users', user.id), {
            allowMultipleResponses: newValue
        })
    }

    const handlePreventDeleteChange = async (e) => {
        const newValue = e.target.checked
        setPreventDelete(newValue)
        await updateDoc(doc(db, 'users', user.id), {
            preventDelete: newValue
        })
    }

    if (loading) {
        return <div className='loader'>
            <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#2D3748"
                ariaLabel="three-dots-loading"
                visible={true}
            />
        </div>
    }

    return (
        <div className="page questionsPage">
            {copied && <div
                className='copiedContainer'>
                <p className='copiedText'>Copied!</p>
            </div>}

            <div className='questionDetails'>
                <div>
                    <p className='questioneeDetails'>Name: {user.name}</p>
                    <p className='questioneeDetails'>Email: {user.email}</p>
                    <div>
                        <Tooltip content="Copy the link to share with the candidate">
                            <button className='questioneeDetails' onClick={copyText}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    style={{ marginRight: '8px' }}
                                >
                                    <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                    <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                </svg>
                                <span>Link: {linkOfTheDocument}</span>
                            </button>
                        </Tooltip>
                    </div>
                    <div>
                        <Tooltip content="When enabled, candidates can submit multiple responses to the same interview">
                            <div className='questioneeDetails' style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '10px'
                            }}>

                                <input
                                    type="checkbox"
                                    id="allowMultipleResponses"
                                    checked={allowMultipleResponses}
                                    onChange={handleAllowMultipleResponsesChange}
                                    style={{
                                        marginRight: '8px',
                                        width: '16px',
                                        height: '16px'
                                    }}
                                />

                                <label htmlFor="allowMultipleResponses">Allow multiple responses</label>
                            </div>
                        </Tooltip>
                    </div>
                    <div>
                        <Tooltip content="When enabled, this user cannot be deleted from the Users screen">
                            <div className='questioneeDetails' style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '10px'
                            }}>

                                <input
                                    type="checkbox"
                                    id="preventDelete"
                                    checked={preventDelete}
                                    onChange={handlePreventDeleteChange}
                                    style={{
                                        marginRight: '8px',
                                        width: '16px',
                                        height: '16px'
                                    }}
                                />

                                <label htmlFor="preventDelete">Prevent Delete</label>
                            </div>
                        </Tooltip>
                    </div>

                </div>

                <Tooltip content="Scan this QR code with a mobile device to access the interview" position="left">
                    <QRCodeSVG value={linkOfTheDocument} size={220} className='qr' />
                </Tooltip>
            </div>

            <h1 className='questionsHead'>
                Questions
            </h1>
            <div className='questionsWrapper'>
                <List
                    values={questions}
                    onChange={drop}
                    renderList={({ children, props }) => {
                        return <div className='questionsWrapper' {...props} key={props.key}>{children}</div>
                    }}
                    renderItem={({ value, props }) => {
                        return <div className='question' key={props.key}>
                            <p {...props}><strong>Q. </strong>{value.value}</p>
                        </div>
                    }}
                />
            </div>

            {user.response && <div className='questionsBtns'>
                <a className='viewResponseBtn' href={user.response} target='_blank' rel="noreferrer">View Latest Response</a>
                <a className='viewResponseBtn' href={user.blackWhiteMerged} target='_blank' rel="noreferrer">View Latest In B/W</a>
                {user.retries && <a className='retryBtn' href={user.retries} target='_blank' rel="noreferrer">View Retries</a>}
            </div>}

            {user.rawVideoUrls && user.rawVideoUrls.length > 0 && (
                <div className='rawVideoUrls'>
                    <div
                        className='expandableHeader'
                        onClick={toggleRawVideos}
                        style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <h1>
                            Raw Video URLs ({user.rawVideoUrls.length})
                            <span style={{ marginLeft: '10px' }}>
                                {rawVideosExpanded ? '▼' : '▶'}
                            </span>
                        </h1>

                    </div>
                    {rawVideosExpanded && (
                        <ol>
                            {user.rawVideoUrls.map((url, index) => (
                                <li key={index + 'rawVideoUrls'}>
                                    <a href={url} target='_blank' rel="noreferrer">{url}</a>
                                </li>
                            ))}
                        </ol>
                    )}
                </div>
            )}

            {user.responseVideoUrls && user.responseVideoUrls.length > 0 && (
                <div className='responseVideoUrls'>
                    <div
                        className='expandableHeader'
                        onClick={toggleResponseVideos}
                        style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <h1>All Response Video URLs ({user.responseVideoUrls.length})
                            <span style={{ marginLeft: '10px' }}>
                                {responseVideosExpanded ? '▼' : '▶'}
                            </span>
                        </h1>

                    </div>
                    {responseVideosExpanded && (
                        <ol>
                            {user.responseVideoUrls.map((obj, index) => (
                                <li key={index + 'responseVideoUrls'}>
                                    <div>Response Set {index + 1}</div>
                                    <ul>
                                        <li>
                                            <a href={obj.color} target='_blank' rel="noreferrer">Color Video</a>
                                        </li>
                                        <li>
                                            <a href={obj.blackWhite} target='_blank' rel="noreferrer">B/W Video</a>
                                        </li>
                                    </ul>
                                </li>
                            ))}
                        </ol>
                    )}
                </div>
            )}
        </div>
    )
}
export default Questions
